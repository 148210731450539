/**
 * 获取当前时间
 * @returns 当前时间字符
 */
function getCurrentTime (): string {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const time = date.toLocaleTimeString()

  return `${year}/${month}/${day} ${time}`
}


export var wsUrl = "wss://fire-platform.ilink-point.com/management/websocket/";

export { getCurrentTime }
