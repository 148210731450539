import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, Avatar, Menu } from 'antd'

import styles from './Header.module.css'
import { getCurrentTime } from '../utils'
import { RollbackOutlined, ClockFilled } from '../components/icon'

const Header: FC = () => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime())
  const [username, setUsername] = useState('')
  const [avatar, setAvatar] = useState('')
  const navigate = useNavigate()

  /**
   * 退出登录
   */
  const onLogout = (): void => {
    sessionStorage.clear()
    window.location.reload();
  }

  const menu = (
    <Menu>

      <Menu.Item onClick={ onLogout }>退出登录</Menu.Item>
    </Menu>
  )

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(getCurrentTime())
    }, 1000)
    const username = sessionStorage.getItem('username')
    if (username !== null) {
      setUsername(username)
      setAvatar(`https://api.multiavatar.com/${username}.svg`)
    }
  }, [currentTime])

  return (
    <div className={ `${styles.header} ${ styles.justifyEnd}` }>
      <div className={ styles.headerRight }>
        <ClockFilled />
        <span className={ styles.time }>{ currentTime }</span>
        <Dropdown overlay={ menu }>
          <div className={ styles.headerRight }>
            <Avatar size={ 34 } src={ avatar } />
            <span className={ styles.username }>{ username }</span>
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default Header
