import {ErrorConstant} from "../../constant/errorConstant";

export type ActionType<T> = {
    type: string
    payload: T
}


export type ErrorActionPayloadType = {
    msg?: string
    state: number
}


export function getActionType<T>(type: string, payload: T): ActionType<T> {
    return {
        type,
        payload
    }
}



export function getInterfaceErrorAction(type: string, msg: string): ActionType<ErrorActionPayloadType> {
    return {
        type,
        payload: {
            msg,
            state: ErrorConstant.INTERFACE_ERROR_STATE
        }
    }
}



export function getNetworkErrorAction(type: string): ActionType<ErrorActionPayloadType> {
    return {
        type,
        payload: {
            state: ErrorConstant.NETWORK_ERROR_STATE
        }
    }
}
