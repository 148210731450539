import style from "./Ctl.module.scss"
import {forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Input} from "antd";
import {myAxios} from "../../../utils/axios";
import {bool2num, isNotBlank, showMsg} from "../../../common/common";
import {DownOutlined, LeftOutlined, RightOutlined, UpOutlined} from "@ant-design/icons";


interface Btns {
    valve: number
    pump: number
    shape: number
}

interface ActionBtns {
    up: number
    left: number
    right: number
    down: number
}


function Ctl(props: any, ref: Ref<unknown> | undefined) {
    // console.log(ref);
    useImperativeHandle(ref, () => ({
        echoState: doShow,
        removeTimer: removeTimer
    }))

    const [btns, setBtns] = useState<Btns>({
        valve: 0,
        pump: 0,
        shape: 0,
    });
    const [actionBtn, setActionBtn] = useState<ActionBtns>({
        up: 0,
        left: 0,
        right: 0,
        down: 0
    })
    const [alert, setAlert] = useState<any>({})

    const [timer, setTimer] = useState<any>(null);

    let one = 0;





    function removeTimer() {
        console.log(timer);
        clearInterval(timer);
    }


    useEffect(() => {

    }, [btns])


    useEffect(() => {
        // if (one === 0) {
        //     let tm = setInterval(() => {
        //         echoState();
        //     }, 5000)
        //     setTimer(tm);
        // }
        // one = 1;
    }, [])



    async function ctlValve() {
        // if ()
        if (btns.valve) {
            await deviceControl("CLOSE_VALVE");
        }
        else {
            await deviceControl("OPEN_VALVE");
        }

        // setBtns(Object.assign({}, btns, {valve: bool2num(!btns.valve)}))
    }


    async function ctlPump() {
        if (btns.pump) {
            await deviceControl("CLOSE_PUMP")
        }
        else {
            await deviceControl("OPEN_PUMP")
        }

        // setBtns(Object.assign({}, btns, {pump: bool2num(!btns.pump)}))
    }


    async function ctlShape(code: number) {
        if (code === 0) {
            await deviceControl("WATER_PILLAR_SHAPE")
        }
        else {
            await deviceControl("WATER_MIST_SHAPE")
        }

        // setBtns(Object.assign({}, btns, {shape: code}))
    }

    function sumAction() {
        let sum = 0;
        for (let key in alert) {
            if (key === "up" || key === "down" || key === "left" || key === "right") {
                sum += alert[key];
            }
        }
        return sum;
    }



    async function doUp() {
        if (actionBtn.up) {
            await deviceControl("UP_STOP")
        }
        else {
            if (sumAction() >= 2) {
                showMsg("非法操作");
                return;
            }
            else if (sumAction() === 1){
                if (actionBtn.down) {
                    showMsg("非法操作");
                    return;
                }
            }
            await deviceControl("UP_START")
        }
        // setActionBtn(Object.assign({}, actionBtn, {up: bool2num(!actionBtn.up)}))
    }

    async function doLeft() {
        if (actionBtn.left) {
            await deviceControl("LEFT_STOP")
        }
        else {
            if (sumAction() >= 2) {
                showMsg("非法操作");
                return;
            }
            else if (sumAction() === 1){
                if (actionBtn.right) {
                    showMsg("非法操作");
                    return;
                }
            }
            await deviceControl("LEFT_START");
        }
        // setActionBtn(Object.assign({}, actionBtn, {left: bool2num(!actionBtn.left)}))
    }

    async function doRight() {
        if (actionBtn.right) {
            await deviceControl("RIGHT_STOP")
        }
        else {
            if (sumAction() >= 2) {
                showMsg("非法操作");
                return;
            }
            else if (sumAction() === 1){
                if (actionBtn.left) {
                    showMsg("非法操作");
                    return;
                }
            }
            await deviceControl("RIGHT_START");
        }
        // setActionBtn(Object.assign({}, actionBtn, {right: bool2num(!actionBtn.right)}))
    }

    async function doDown() {
        if (actionBtn.down) {
            await deviceControl("DOWN_STOP")
        }
        else {
            if (sumAction() >= 2) {
                showMsg("非法操作");
                return;
            }
            else if (sumAction() === 1){
                if (actionBtn.up) {
                    showMsg("非法操作");
                    return;
                }
            }
            await deviceControl("DOWN_START");
        }
        // setActionBtn(Object.assign({}, actionBtn, {down: bool2num(!actionBtn.down)}))
    }



    async function deviceControl(control: string) {
        let res: any = await myAxios.post("/order/control", {
            control,
            deviceIdentifier: props.ctlId
        })
        if (res.data.code !== 200) {
            throw new Error(res.data.msg)
        }
        echoState();
        console.log(res);

    }


    function doShow() {
        if (one === 0) {
            let tm = setInterval(() => {
                echoState();
            }, 3000)
            setTimer(tm);
        }
        echoState();
    }


    async function echoState() {
        let res: any = await myAxios.get("/device/getCannonState", {
            deviceId: props.ctlDeviceId
        })
        console.log(res);
        if (res.data.data !== null) {
            let data: any = res.data.data;
            setBtns({
                pump: data.pumpState,
                shape: data.waterType,
                valve: data.brakeState
            });
            setActionBtn({
                down: data.down,
                left: data.left,
                right: data.right,
                up: data.up
            })
            setAlert(data)
        }
        else {
            showMsg("未找到设备");
        }
    }



    async function reset() {
        await deviceControl("RESET");
        // await deviceControl("SELECT");
        echoState();
    }












    return (
        <div className={style.ctlBox}>
            <div className={style.ctl}>
                <div className={style.mainBox}>
                    <div className={style.leftBox}>
                        <div>
                            <Button onClick={ctlValve} type={btns.valve ? "primary" : "default"}>阀</Button>
                            <Button onClick={ctlPump} type={btns.pump ? "primary" : "default"} style={{marginLeft: "auto"}}>泵</Button>
                        </div>
                        <div style={{marginTop: "auto"}}>
                            <Button onClick={() => ctlShape(0)} type={btns.shape === 0 ? "primary" : "default"}>柱</Button>
                            <Button onClick={() => ctlShape(1)} type={btns.shape === 1 ? "primary" : "default"} style={{marginLeft: "auto"}}>雾</Button>
                        </div>
                    </div>
                    <div className={style.rightBox}>
                        <div className={style.rightBox1}>
                            <Button icon={<UpOutlined />} size="large" shape={"circle"} onClick={doUp} type={actionBtn.up ? "primary" : "default"}></Button>
                        </div>
                        <div>
                            <Button icon={<LeftOutlined />} size="large" shape={"circle"} onClick={doLeft} type={actionBtn.left ? "primary" : "default"}></Button>
                            <Button icon={<RightOutlined />} size="large" shape={"circle"} onClick={doRight} type={actionBtn.right ? "primary" : "default"} style={{marginLeft: "auto"}}></Button>
                        </div>
                        <div className={style.rightBox3}>
                            <Button icon={<DownOutlined />} size="large" shape={"circle"} onClick={doDown} type={actionBtn.down ? "primary" : "default"}></Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.ctl}>
                <div className={style.alertButtonBox}>
                    <div>
                        <Button onClick={() => deviceControl("SELECT")}>查询</Button>
                    </div>
                    <div style={{width: "270px", display: "flex", flexWrap: "wrap"}}>
                        <Button onClick={() => deviceControl("AREA1_ALERT")}>触发区域1报警</Button>
                        <Button onClick={() => deviceControl("AREA2_ALERT")}>触发区域2报警</Button>
                        <Button onClick={() => deviceControl("AREA3_ALERT")}>触发区域3报警</Button>
                        <Button onClick={() => deviceControl("AREA4_ALERT")}>触发区域4报警</Button>
                    </div>
                    <div>
                        <Button onClick={() => reset()}>重置</Button>
                    </div>
                </div>
            </div>
            <div style={{marginTop: 20}}>
                <span style={{color: alert.alertOne ? 'red' : ""}}>区域1:{alert.alertOne ? "灭火中" : "正常"}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{color: alert.alertTwo ? 'red' : ""}}>区域2:{alert.alertTwo ? "灭火中" : "正常"}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{color: alert.alertThree ? 'red' : ""}}>区域3:{alert.alertThree ? "灭火中" : "正常"}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{color: alert.alertFour ? 'red' : ""}}>区域4:{alert.alertFour ? "灭火中" : "正常"}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
        </div>
    )
}


export default (forwardRef(Ctl))