export const ACTIONS = {
    GET_LIST: "getList",
    DEL_ITEM: "delItem",
    EDIT_ITEM: "editItem",
    ADD_ITEM: "addItem",
    DISABLE_ENABLE_ITEM: "disableEnableItem",


    RESPONSE_SUCCESS: "responseSuccess",
    RESPONSE_ERROR: "responseError",


    RESET_PARAMS_STATE: "resetParamsState",
    SET_PARAMS_STATE: "setParamsState",


    RESET_ADD_EDIT_STATE: "resetAddEditState",




    LOGIN_ACTION: "loginAction",
    COMPANY_LIST: "companyList",
}