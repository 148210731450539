import {addCompany, delCompanyList, disableEnableCompany, editCompany, getCompanyList} from "../../../service/company";
import {addProject, delProject, editProject, getProjectList} from "../../../service/project";
import {addNotify, delNotify, getDeviceList, getNotifyList} from "../../../service/device";

export const requestFnMap: any = {
    "index": {
        "getList": getDeviceList,
    },
    "callback-list": {
        "getList": getNotifyList,
        "addItem": addNotify,
        "delItem": delNotify
    }
}