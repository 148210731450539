import {createStore, applyMiddleware} from "redux";
import {rootReducer} from "./reducers"
import {defSaga} from "./sagas";
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware();

// @ts-ignore
export default createStore(rootReducer, {}, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(defSaga);