import {ActionType, getActionType} from "../../common/types/actionType";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useEffect, useState} from "react";
import {ACTIONS} from "../../constant/actionConstant";
import {useForm} from "antd/lib/form/Form";
import {objectIsEmpty, showMsg} from "../../common/common";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {ErrorConstant} from "../../constant/errorConstant";


const delItemKey = "ids";

const disableEnableIdKey = "ids";
const disableEnableStateKey = "status";


export function useTableAction (alwaysGetListParams: any): [any[], FormInstance<any>, FormInstance<any>, (() => void), (() => void), ((ids: any[]) => void), ((ids: any[], state: number) => void), (() => Promise<void>), boolean, ((page: number, pageSize?: number) => void), number, number, number, (() => Promise<void>), boolean, (() => void), (() => void), boolean, ((record: any) => void), (() => void), number, any, any] {
    const dispatch = useDispatch();
    const table = useSelector((state: any) => state.list.table);
    const params = useSelector((state: any) => state.list.params);
    const addEdit = useSelector((state: any) => state.list.addEdit);
    const [form] = useForm();
    const [editForm] = useForm();
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [total, setTotal] = useState(0);
    const [pages, setPages] = useState(0);
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    let isMo = true;



    const key = window.location.href.split("/")[window.location.href.split("/").length - 1].replaceAll("/", "");


    useEffect(() => {
        if (isMo) {
            console.log("in")
            console.log(params);
            let nowParams = params[key];
            console.log(nowParams);
            if (!objectIsEmpty(nowParams)) {
                form.setFieldsValue(nowParams);
            }
            dispatch(getActionType(key + ACTIONS.GET_LIST, objectIsEmpty(nowParams) ? alwaysGetListParams : nowParams));
        }
        isMo = false;
        return () => {
            resetAddEditState();
        }
    }, []);


    useEffect(() => {
        console.log("table update------>", table);
        if (table.list && table.type.includes(key)) {
            setTimeout(() => {
                setList(table.list);
                setCurrent(table.current);
                setTotal(table.total);
                setPages(table.pages);
                setPageSize(table.size);
                if (table.list.length === 0 && table.current !== 1 && table.total !== 0) {
                    // dispatch(getActionType(key + ACTIONS.GET_LIST, {...table.payload, page: 1, ...alwaysGetListParams}));
                }
            }, 0)
        }
    }, [table])


    useEffect(() => {
        console.log("params update------->", params);
    }, [params])

    useEffect(() => {
        console.log("put callback------->", addEdit);
        if (!objectIsEmpty(addEdit)) {
            setLoading(false);
            console.log("put callback------->", addEdit);
            if (addEdit.payload.state === ErrorConstant.SUCCESS_CODE) {
                // showMsg("操作成功");
                search();
                hiddenDialog();
                hiddenEditDialog();
            }
            else if (addEdit.payload.state === ErrorConstant.INTERFACE_ERROR_STATE) {
                // showMsg(addEdit.payload.msg);
            }
            else {
                showMsg("网络错误，请检查网络");
            }
        }
    }, [addEdit])


    function resetAddEditState() {
        console.log("reset");
        dispatch(getActionType(ACTIONS.RESET_ADD_EDIT_STATE, {}));

    }



    function hiddenDialog() {
        editForm.resetFields();
        setVisible(false);
    }


    function showDialog() {
        setVisible(true);
    }


    function hiddenEditDialog() {
        editForm.resetFields();
        setEditVisible(false);
    }


    function showEditDialog(record: any) {
        setTimeout(()=>{
            editForm.setFieldsValue(record)
        },0)
        setEditVisible(true);
    }



    function reset() {
        form.resetFields();
        dispatch(getActionType(key + ACTIONS.GET_LIST, {...alwaysGetListParams}));
    }


    function onPageChange(page: number, pageSize?: number) {
        dispatch(getActionType(key + ACTIONS.GET_LIST, {...params[key], page, pageSize, ...alwaysGetListParams}));
    }


    function search() {
        console.log(params);
        dispatch(getActionType(key + ACTIONS.GET_LIST, {...params[key], ...form.getFieldsValue(), ...alwaysGetListParams}));
    }


    function del(ids: any[]) {
        let delObj: any = {};
        delObj[delItemKey] = ids;
        console.log(ids);
        dispatch(getActionType(key + ACTIONS.DEL_ITEM, delObj));
    }

    function disableEnable(ids: any[], state: number) {
        let disableEnableObj: any = {};
        disableEnableObj[disableEnableIdKey] = ids;
        disableEnableObj[disableEnableStateKey] = state;
        dispatch(getActionType(key + ACTIONS.DISABLE_ENABLE_ITEM, disableEnableObj));
    }


    async function edit() {
        setLoading(true);
        try {
            let val: any = await editForm.validateFields()
            dispatch(getActionType(key + ACTIONS.EDIT_ITEM, val));
        }
        catch (e) {
            showMsg("请检查表单是否填写完整");
            setLoading(false);
        }
    }

    async function add() {
        setLoading(true);
        try {
            let val: any = await editForm.validateFields()
            dispatch(getActionType(key + ACTIONS.ADD_ITEM, val));
        }
        catch (e) {
            showMsg("请检查表单是否填写完整");
            setLoading(false);
        }
    }


    return [list, form, editForm, search, reset, del, disableEnable, edit, loading, onPageChange, current, total, pages, add, visible, showDialog, hiddenDialog, editVisible, showEditDialog, hiddenEditDialog, pageSize, selectedRowKeys, setSelectedRowKeys];


}
