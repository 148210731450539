import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Login from "./pages/login";

import store from "./store"
import {Provider} from "react-redux";
import {getQueryVariable, isNotBlank} from "./common/common";
import Layout from "./layout";
import DeviceList from "./pages/device-list";
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN'
import CallbackList from "./pages/callback-list";




function App() {




    function checkLogin() {
        let t = getQueryVariable("t");
        if (t) {
            console.log(t);
            sessionStorage.setItem("token", t);
        }
        console.log(window.location.href.split("/"));
        let url = window.location.href.split("/")[window.location.href.split("/").length - 1];
        let token = sessionStorage.getItem("token");
        if (isNotBlank(token)) {
            window.history.pushState(null, "", "/home/index")
        }
        else {
            window.history.pushState(null, "", "/login")
        }
    }


    return (
      <Provider store={store} >
          <ConfigProvider locale={ zhCN }>
              <div className="App">
                  {
                      // @ts-ignore
                      <BrowserRouter>
                          <Routes>
                              <Route element={<Login />} path={"/login"}/>
                              <Route element={<Layout />} path={"/home"}>
                                  <Route element={<DeviceList />} path={"/home/index"}/>
                                  <Route element={<CallbackList />} path={"/home/callback-list"}/>
                              </Route>
                          </Routes>
                          {
                              checkLogin()
                          }
                      </BrowserRouter>
                  }

              </div>
          </ConfigProvider>
      </Provider>
  );
}

export default App;
