import {ActionType} from "../../../common/types/actionType";
import {ACTIONS} from "../../../constant/actionConstant";

export function tableReducer(state = {}, action: ActionType<any>) {
    let key = window.location.href.split("/")[window.location.href.split("/").length - 1].replaceAll("/", "");
    switch (action.type) {
        case key + ACTIONS.GET_LIST:
            console.log(123, action);
            return Object.assign({}, action);
        case key + ACTIONS.GET_LIST + ACTIONS.RESPONSE_SUCCESS:
            console.log(action.payload);
            return Object.assign({}, state, action.payload);
        case key + ACTIONS.GET_LIST + ACTIONS.RESPONSE_ERROR:
            break;
    }

    return state
}