import {notification} from "antd";


const host = "http://127.0.0.1:3000"
const defaultImage ="http://150.158.83.131:9999/img/WechatIMG2715.png"


const pageCacheKey = "pck";

export function showMsg(str: string) {
    notification.info({
        message: str,
        duration: 2
    })
}

export function weekNum2str(num: number) {
    switch (num) {
        case 1:
            return "一"
        case 2:
            return "二"
        case 3:
            return "三"
        case 4:
            return "四"
        case 5:
            return "五"
        case 6:
            return "六"
        case 7:
            return "天"
    }
}


export function objectIsEmpty(obj: any) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
}


export function getQueryVariable(variable: string)
{
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return false;
}




export function reflex(originObj: any, originArr: string[], reflexArr: string[]): any {
    if (originArr.length !== reflexArr.length) {
        new Error("origin arr length not eq reflex arr length, can't reflex!");
    }
    let obj: any = {};
    for (let i = 0; i < originArr.length; i ++) {
        for (let key in originObj) {
            if (originArr[i] === key) {
                obj[reflexArr[i]] = originObj[key];
                break;
            }
        }
    }
    return obj
}


export function forceRouterPush(path: string): void {
    console.log(window.location.href);
    let host: string = window.location.href.split("/")[0] + "//" + window.location.href.split("/")[2];
    window.location.href = host + path
}


export function arr2str8mark(arr: string[], mark: string): string {
    let str: string = "";
    for (let i = 0; i < arr.length; i ++) {
        if (i === arr.length - 1) {
            str += arr[i]
            break;
        }
        str += arr[i] + mark
    }
    return str
}


export function filterObjDelNull(obj: any) {
    for (let key in obj) {
        if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
            delete obj[key]
        }
        // else {
        //     if (url !== "/goods/addOrUpdateGoods" && url !== "/protocolConfig/setProtocol" && url !== "/protocolConfig/putProtocol") {
        //         // console.log(obj[key]);
        //         if (typeof obj[key] === 'string') {
        //             obj[key] = obj[key].toString().replace(/ /g, "");
        //         }
        //     }
        // }
    }
    return obj
}


export function sleep(d: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, d)
    })
}


export function jumpToLogin() {
    let host: string = window.location.href.split("/")[2];
    window.location.href = "http://" + host + "/#/login"
}



















function isPrimitive(value: any){
    return (typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'symbol' ||
        typeof value === 'boolean')
}


function isObject(value: any){
    return Object.prototype.toString.call(value) === "[object Object]"
}


export function deepClone(value: any){


    let memo: any = {};

    // eslint-disable-next-line @typescript-eslint/no-shadow
    function baseClone(value: any){
        let res: any;
        if(isPrimitive(value)){
            return value;
        }else if(Array.isArray(value)){
            res = [...value];
        }else if(isObject(value)){
            res = {...value};
        }
        Reflect.ownKeys(res).forEach(key=>{
            if(typeof res[key] === "object" && res[key]!== null){
                if(memo[res[key]]){
                    res[key] = memo[res[key]];
                }else{
                    memo[res[key]] = res[key];
                    res[key] = baseClone(res[key])
                }
            }
        })
        return res;
    }

    return baseClone(value)
}



export function getQueryString(name: string){
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if(r!=null)return  decodeURI(r[2]); return null;
}

export function isBlank(val: any) {
    return (!val !== undefined && val !== null && val !== "")
}


export function isNotBlank(val: any) {
    return val !== undefined && val !== null && val !== ""
}



function setCacheObj(key: any, val: any) {
    sessionStorage.setItem(key, JSON.stringify(val));
}


function getCacheObj(key: any) {
    if (sessionStorage.getItem(key)) {
        return JSON.parse(sessionStorage.getItem(key)!);
    }
    return null;
}


function getNowPageCacheKey() {
    return window.location.href.split("/")[window.location.href.split("/").length - 1];
}


export function setCacheToForm(formIns: any) {
    let key = getNowPageCacheKey();
    let cache: any = getCacheObj(key);
    if (cache) {
        formIns.current?.setFieldsValue(cache);
    }
}




export async function commonGetList(params: any, searchFn: Function, ctx: any, alwaysParams?: any, activeFn?: Function, delFn?: Function, callMode?: any) {
    let searchObj = {...params, ...alwaysParams};
    let key = getNowPageCacheKey();
    if (!callMode) {
        let cacheObj = getCacheObj(key);
        if (cacheObj) {
            searchObj = cacheObj
        }
        else {
            setCacheObj(key, searchObj);
        }
    }
    else {
        setCacheObj(key, searchObj);
    }




    let data: any = await searchFn(searchObj);

    ctx.setState({
        res: {
            dataSource: data.data.data.records ? data.data.data.records : data.data.data,
            total: data.data.data.total,
            size: data.data.data.size,
            pages: data.data.data.pages,
            current: data.data.data.current,
            selectedRowKeys: [],
            onPageChange: async (page: number, pageSize?: number) => {
                console.log(page);
                console.log(pageSize);
                commonGetList({...searchObj, ...params, page, pageSize}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
            },
            onSearch: async (formIns: any) => {
                setTimeout( async () => {
                    console.log(formIns);
                    let form = await formIns.current?.validateFields();
                    let obj = filterObjDelNull(form);
                    commonGetList({...obj, pageSize: params.pageSize}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
                }, 0)
            },
            onReset: (formIns: any) => {
                formIns.current?.resetFields();
                ctx.state.res.onSearch(formIns);
            },
            onSelectedRowKeysChange: (selectKey: any) => {
                console.log(selectKey);
                let nowRes = ctx.state.res;
                nowRes.selectedRowKeys = selectKey;
                ctx.setState({
                    res: nowRes
                })
            },
            onSelectChange: (selectKey: any) => {
                console.log(selectKey);
                let nowRes = ctx.state.res;
                nowRes.selectedRowKeys = selectKey;
                ctx.setState({
                    res: nowRes
                })
            },
            onDelete: async (ids: any) => {
                console.log(ids);
                await delFn!({ids});
                commonGetList({...params}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
            },
            onActive: async (paramsAc: any) => {
                let obj: any = {};
                for (let key in paramsAc) {
                    if (Array.isArray(paramsAc[key])) {
                        obj.ids = paramsAc[key]
                    }
                    else if (key === "state") {
                        obj.status = paramsAc[key]
                    }
                }
                await activeFn!(obj);
                commonGetList({...params}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
            },
            onReload:async (pageTo1?: boolean) => {
                if (pageTo1) {
                    commonGetList({page: 1}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
                }
                else {
                    commonGetList({...params}, searchFn, ctx, alwaysParams, activeFn, delFn, 1);
                }
            }
        }
    })
}

export function bool2num(bool: any) {
    return bool ? 1 : 0;
}




export async function commonGetList1(params: any, searchFn: Function, ctx: any, alwaysParams?: any, activeFn?: Function, delFn?: Function) {
    let data: any = await searchFn({...params, ...alwaysParams});
    ctx.setState({
        res1: {
            dataSource: data.data.data.records,
            total: data.data.data.total,
            size: data.data.data.size,
            pages: data.data.data.pages,
            current: data.data.data.current,
            selectedRowKeys: [],
            onPageChange: async (page: number, pageSize?: number) => {
                console.log(page);
                console.log(pageSize);
                commonGetList1({...params, page, pageSize}, searchFn, ctx, alwaysParams, activeFn, delFn);
            },
            onSearch: async (formIns: any) => {
                let form = await formIns.current?.validateFields();
                let obj = filterObjDelNull(form);
                commonGetList1({...obj, pageSize: params.pageSize}, searchFn, ctx, alwaysParams, activeFn, delFn);
            },
            onReset: (formIns: any) => {
                formIns.current?.resetFields();
                ctx.state.res1.onSearch(formIns);
            },
            onSelectedRowKeysChange: (selectKey: any) => {
                console.log(selectKey);
                let nowRes = ctx.state.res1;
                nowRes.selectedRowKeys = selectKey;
                ctx.setState({
                    res1: nowRes
                })
            },
            onSelectChange: (selectKey: any) => {
                console.log(selectKey);
                let nowRes = ctx.state.res1;
                nowRes.selectedRowKeys = selectKey;
                ctx.setState({
                    res1: nowRes
                })
            },
            onDelete: async (ids: any) => {
                console.log(ids);
                await delFn!({ids});
                commonGetList1({...params}, searchFn, ctx, alwaysParams, activeFn, delFn);
            },
            onActive: async (paramsAc: any) => {
                let obj: any = {};
                for (let key in paramsAc) {
                    if (Array.isArray(paramsAc[key])) {
                        obj.ids = paramsAc[key]
                    }
                    else if (key === "state") {
                        obj.status = paramsAc[key]
                    }
                }
                await activeFn!(obj);
                commonGetList1({...params}, searchFn, ctx, alwaysParams, activeFn, delFn);
            },
            onReload:async () => {
                commonGetList1({...params}, searchFn, ctx, alwaysParams, activeFn, delFn);
            }
        }
    })
}
