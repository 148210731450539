import React, { FC, Key, useEffect, useState } from 'react'
import { Layout, Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useNavigate } from 'react-router-dom'

import Logo from '../assets/images/link-point-logo.png'
import {
  SafetyFilled,
  SafetyOutlined,
  ExtinguisherFilled,
  ExtinguisherOutlined,
  ProjectFilled,
  ProjectOutlined,
  FileTextFilled,
  FileTextOutlined,
  CompanyFilled,
  CompanyOutlined,
  UserGroupFilled,
  UserGroupOutlined,
  WarningFilled,
  WarningOutlined,
  SettingFilled,
  SettingOutlined,
  HomeFilled,
  HomeOutlined
} from '../components/icon'
import styles from './Sidebar.module.css'

const Sidebar: FC = () => {
  const [key, setKey] = useState<Key>("home/index")
  const [routes, setRoutes] = useState<string[]>([])
  const [permissionRoutes, setPermissionRoutes] = useState<any>([])
  const navigate = useNavigate()

  const handleClick = (e: MenuInfo): void => {
    console.log('click ', e)
    setKey(e.key)
    navigate(`/${e.key}`)

    sessionStorage.setItem('key', e.key.toString())
  }
  const reloadRouter = ()=>{
    setInterval(()=>{
      let str = window.location.href.split('/')[window.location.href.split('/').length-2] + "/" + window.location.href.split('/')[window.location.href.split('/').length-1]
      setKey(str)
    },100)
  }

  useEffect(() => {
    let arr: any =  window.location.href.split("/");
    sessionStorage.setItem("key", arr[arr.length - 1]);

    setKey(sessionStorage.getItem('key') ?? 'home/index')
    // 有权限的路由（登录时获取）
    const sessionRoutes = sessionStorage.getItem('routes')
    if (sessionRoutes !== null) {
      setRoutes(JSON.parse(sessionRoutes))
    }
    reloadRouter()
    getRouteList();
  }, [])


  const getRouteList = () => {
    let sessionRoutes: any = sessionStorage.getItem('menu');
    setPermissionRoutes([
      {
        menuName: "设备列表",
        key: "/home/index",
        menuCode: "/home/index",
        children: []
      },
      {
        menuName: "回调配置",
        key: "/home/callback-list",
        menuCode: "/home/callback-list",
        children: []
      }
    ]);
    // if (sessionRoutes === null) {
    //   setPermissionRoutes([]);
    //   return;
    // }
    // sessionRoutes = JSON.parse(sessionRoutes);
    // for (let i = 0; i < 2; i ++) {
    //   for (let j = 0; j < sessionRoutes.length; j ++) {
    //     if (i === 0) {
    //       if (sessionRoutes[j].showInMenu != 1) {
    //         sessionRoutes.splice(j, 1);
    //         j --;
    //         continue;
    //       }
    //       sessionRoutes[j].children = [];
    //     }
    //     if (i === 1) {
    //       if (sessionRoutes[j].parentId == 0) {
    //         for (let k = 0; k < sessionRoutes.length; k ++) {
    //           if (sessionRoutes[j].menuId == sessionRoutes[k].parentId) {
    //             sessionRoutes[j].children.push(sessionRoutes[k]);
    //             sessionRoutes.splice(k, 1);
    //             k --;
    //             j --;
    //           }
    //         }
    //         sessionRoutes[j].children = sessionRoutes[j].children.sort((a: any, b: any) => a.sortKey - b.sortKey);
    //       }
    //     }
    //   }
    // }
    // console.log(sessionRoutes);
    // sessionRoutes = sessionRoutes.sort((a: any, b: any) => a.sortKey - b.sortKey);
    // setPermissionRoutes(sessionRoutes);
  }



  return (
    <Layout.Sider
      style={{
        background: '#242833',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        overflowX: 'hidden'
      }}
      width={240}
    >
      <div className={styles.logoWrapper}>
        <h2 style={{color: "white"}}>智能水炮</h2>
        {/*<img className={styles.logo} src={Logo} />*/}
      </div>
      <Menu
        defaultSelectedKeys={['dashboard']}
        mode='inline'
        selectedKeys={[key.toString()]}
        style={{ background: '#242833', color: 'white' }}
        theme={'dark'}
        onClick={handleClick}
      >







        {
          permissionRoutes.map((val: any, key: any) =>
              val.children.length > 0 ?
                  <Menu.SubMenu key={key} title={val.menuName}>
                    {
                      val.children.map((val1: any, key1: any) =>
                          <Menu.Item key={val1.menuCode.replace("/", "")}>{val1.menuName}</Menu.Item>
                      )
                    }
                  </Menu.SubMenu>
                  :
                  <Menu.Item key={val.menuCode.replace("/", "")}>{val.menuName}</Menu.Item>
          )
        }









        {/*{routes.includes('/dashboard') ? (*/}
        {/*  <Menu.Item icon={key === 'dashboard' ? <HomeFilled /> : <HomeOutlined />} key='dashboard'>*/}
        {/*    首页*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/service-provider') || routes.includes('/end-user') ? (*/}
        {/*  <Menu.SubMenu*/}
        {/*    icon={*/}
        {/*      key === '6' ? (*/}
        {/*        <CompanyFilled style={{ marginRight: 10 }} />*/}
        {/*      ) : (*/}
        {/*        <CompanyOutlined style={{ marginRight: 10 }} />*/}
        {/*      )*/}
        {/*    }*/}
        {/*    key={'6'}*/}
        {/*    title={'企业管理'}*/}
        {/*  >*/}
        {/*    {routes.includes('/service-provider') ? <Menu.Item key={'service-provider'}>服务商管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/end-user') ? <Menu.Item key={'end-user'}>终端用户管理</Menu.Item> : null}*/}
        {/*  </Menu.SubMenu>*/}
        {/*) : null}*/}
        {/*{routes.includes('/projects') ? (*/}
        {/*  <Menu.Item icon={key === 'projects' ? <ProjectFilled /> : <ProjectOutlined />} key='projects'>*/}
        {/*    项目管理*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/protection-areas') ? (*/}
        {/*  <Menu.Item icon={key === 'protection-areas' ? <SafetyFilled /> : <SafetyOutlined />} key='protection-areas'>*/}
        {/*    防护区管理*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/device') ||*/}
        {/*routes.includes('/gateways') ||*/}
        {/*routes.includes('/all-in-one-machine') ||*/}
        {/*routes.includes('/monitor-devices') ? (*/}
        {/*  <Menu.SubMenu*/}
        {/*    icon={*/}
        {/*      key === 'devices' ? (*/}
        {/*        <ExtinguisherFilled style={{ marginRight: 10 }} />*/}
        {/*      ) : (*/}
        {/*        <ExtinguisherOutlined style={{ marginRight: 10 }} />*/}
        {/*      )*/}
        {/*    }*/}
        {/*    key={'devices'}*/}
        {/*    title={'设备管理'}*/}
        {/*  >*/}
        {/*    {routes.includes('/device') ? <Menu.Item key='device'>消防设备管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/gateways') ? <Menu.Item key='gateways'>网关管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/all-in-one-machine') ? <Menu.Item key='all-in-one-machine'>一体机管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/monitor-devices') ? <Menu.Item key='monitor-devices'>监控设备</Menu.Item> : null}*/}
        {/*    {routes.includes('/fire-monitor') ? <Menu.Item key='fire-monitor'>火焰摄像头</Menu.Item> : null}*/}
        {/*  </Menu.SubMenu>*/}
        {/*    ) : null}*/}

        {/*{routes.includes('/user') ? (*/}
        {/*  <Menu.Item icon={key === 'user' ? <UserGroupFilled /> : <UserGroupOutlined />} key='user'>*/}
        {/*    用户管理*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/alarm') ? (*/}
        {/*  <Menu.Item icon={key === 'alarm' ? <WarningFilled /> : <WarningOutlined />} key='alarm'>*/}
        {/*    报警管理*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/log') ? (*/}
        {/*  <Menu.Item icon={key === 'log' ? <FileTextFilled /> : <FileTextOutlined />} key='log'>*/}
        {/*    系统日志*/}
        {/*  </Menu.Item>*/}
        {/*) : null}*/}
        {/*{routes.includes('/permission') ||*/}
        {/*routes.includes('/messages') ||*/}
        {/*routes.includes('/dictionaries') ||*/}
        {/*routes.includes('/device-types') ||*/}
        {/*routes.includes('/device-features') ||*/}
        {/*routes.includes('/device-models') ? (*/}
        {/*  <Menu.SubMenu*/}
        {/*    icon={*/}
        {/*      key === '12' ? (*/}
        {/*        <SettingFilled style={{ marginRight: 10 }} />*/}
        {/*      ) : (*/}
        {/*        <SettingOutlined style={{ marginRight: 10 }} />*/}
        {/*      )*/}
        {/*    }*/}
        {/*    key={'12'}*/}
        {/*    title={'系统设置'}*/}
        {/*  >*/}
        {/*    {routes.includes('/permission') ? <Menu.Item key={'permission'}>权限配置</Menu.Item> : null}*/}
        {/*    {routes.includes('/messages') ? <Menu.Item key={'messages'}>消息管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/dictionaries') ? <Menu.Item key={'dictionaries'}>字典管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/device-types') ? <Menu.Item key={'device-types'}>设备类型管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/device-features') ? <Menu.Item key={'device-features'}>设备子类型管理</Menu.Item> : null}*/}
        {/*    {routes.includes('/device-models') ? <Menu.Item key={'device-models'}>设备型号管理</Menu.Item> : null}*/}
        {/*  </Menu.SubMenu>*/}
        {/*    ) : null}*/}
      </Menu>
    </Layout.Sider>
  )
}

export default Sidebar
