import {AxiosRequestConfig} from "axios";
import {Request} from "./request";

export type requestContent = AxiosRequestConfig

export interface requestInterface {
    get<R>(url: string, data: any): Promise<R>
    post<R>(url: string, data: any): Promise<R>
    put<R>(url: string, data: any): Promise<R>
    del<R>(url: string, data: any): Promise<R>
    upLoadFile<R>(url: string, data: any): Promise<R>
}


export const myAxios = new Request()


