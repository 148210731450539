import css from "../device-list/index.module.css";
import Search from "./component/Search";
import ServiceProviderTable from "./component/ServiceProviderTable";
import {Form, Input, Modal} from "antd";
import Ctl from "../device-list/component/Ctl";
import {useTableAction} from "../../hooks/tableHook";

function CallbackList() {
    const [dataSource, searchForm, editForm, search, reset, del, disableEnable, editFn, loading, onPageChange, current, total, pages, addFn, visible, showDialog, hiddenDialog, editVisible, showEditDialog, hiddenEditDialog, pageSize, selectedRowKeys, setSelectedRowKeys] = useTableAction({});



    return (
        <div className={css.page}>
            <Search form={searchForm} reset={reset} add={showDialog} query={search}/>
            <ServiceProviderTable
                onPageChange={onPageChange}
                onSelectedRowKeysChange={(e: any)=>{
                    setSelectedRowKeys(e);
                }}
                onEdit={showEditDialog}
                current={ current }
                // current={ companyList.current }
                pages={  pages }
                pageSize={pageSize}
                // pages={  companyList.pages }
                total={ total }
                // total={ companyList?.total }
                dataSource={dataSource}
                selectedRowKeys={selectedRowKeys}
                onActive={(params) => {disableEnable(params.id, params.state)}}
                onDelete={ (userIds) => Modal.confirm({
                    onOk: () => del(userIds),
                    title: '确认删除回调地址？',
                    content: '删除后不可恢复！'
                }) }
            />
            <Modal title={'新增'}
                   visible={visible}
                   onOk={addFn}
                   confirmLoading={loading}
                   onCancel={hiddenDialog}>
                <Form form={editForm} labelCol={ { span: 6 } } size={ 'middle' }>
                    <Form.Item label={'回调地址'} name={'url'} >
                        <Input/>
                    </Form.Item>
                </Form>
            </Modal>
            {/*<Modal title={'操作'} visible={editVisible} onOk={hiddenEditDialog} onCancel={hiddenEditDialog}>*/}
            {/*    /!*<Ctl ref={ctlRef} ctlDeviceId={ctlDeviceId} ctlId={ctlId}/>*!/*/}
            {/*</Modal>*/}
        </div>
    )
}


export default CallbackList;