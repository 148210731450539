import React, {FC, Suspense, useEffect} from 'react';
import {Outlet} from 'react-router-dom'
import { Layout as AntdLayout } from 'antd'
import Sidebar from "./Sidebar";
import Header from "./Header";
import Content from "./Content";


const Layout:FC=()=> {

    return (
        <AntdLayout>
            <Sidebar />
            <AntdLayout>
                <Header />
                <Content />
            </AntdLayout>
        </AntdLayout>
    );

}

export default Layout;
