import {myAxios} from "../utils/axios";

export const getDeviceList = (params: any) => myAxios.get(`/device/list`, {...params});


export const getNotifyList = (params: any) => myAxios.get("/getCallbackUrlList", {...params});


export const addNotify = (params: any) => myAxios.post("/addCallBackUrl", {...params});

export const delNotify = (params: any) => myAxios.post("/delNotify", {...params});