import React, {FC, useEffect, useRef, useState} from 'react';
import './index.css'
import IconAccount from '/src/assets/icons/account.png'
import IconPassword from '/src/assets/icons/password.png'
import {Form } from "antd";
// import {login} from "../../apis/login";
import {useForm} from "antd/lib/form/Form";
import {message, notification} from "antd/es";
import {useNavigate} from "react-router-dom";
import unSelect from '/src/assets/icons/un-select.png'
import Select from '/src/assets/icons/selected.png'
import css from './index.module.css'
import {login} from "../../service/login";
import {forceRouterPush, isNotBlank, showMsg} from "../../common/common";
import {useDispatch, useSelector, useStore} from "react-redux";
import {ACTIONS} from "../../constant/actionConstant";
import {ActionType} from "../../common/types/actionType";
import {LoginType} from "../../common/types/loginType";


const Login :FC=(props)=> {
    const navigate = useNavigate()
    const [form] = useForm()
    const [img,setImg] = useState<boolean>(false)
    const dispatch = useDispatch();
    const first = useRef(false);

    const state = useSelector((state1: any) => state1.user);

    const store = useStore();

    // const loginButton = useMutation(async ()=> await login({...form.getFieldsValue(),
    //     password:Md5(form.getFieldValue('password')),source:1}),{onSuccess:(data)=>{
    //         sessionStorage.setItem('token', data.token)
    //         sessionStorage.setItem('username', data.username)
    //         sessionStorage.setItem('routes', JSON.stringify(data.sysMenuList.map((item:any) => item.menuUrl)))
    //         sessionStorage.setItem('permission',data.userType)
    //         sessionStorage.setItem('operatorId',data.operatorId)
    //         sessionStorage.setItem('account',data.account)
    //         sessionStorage.setItem('phoneNumber',data.phoneNumber)
    //         if (img){
    //             sessionStorage.setItem('username5',form.getFieldValue('username'))
    //             sessionStorage.setItem('password5',form.getFieldValue('password'))
    //         }else {
    //             sessionStorage.removeItem('username5')
    //             sessionStorage.removeItem('password5')
    //         }
    //         navigate('/homepage')
    //         console.log(data)
    //     },onError:(error:any)=>{
    //         console.log(error);
    //         message.error(error.message)
    //     }})

    useEffect(()=>{
        if (isNotBlank(localStorage.getItem("u"))){
            console.log(1);
            setImg(true)
            form.setFieldsValue({username:localStorage.getItem("u"),password:localStorage.getItem('p')})
        }
        else {
            console.log(2);
            setImg(false);
        }

        // setInterval(() => {
        //     console.log(store.getState());
        // }, 1000)

    },[]);


    useEffect(() => {
        console.log(state);
        switch (state.type) {
            case ACTIONS.LOGIN_ACTION + ACTIONS.RESPONSE_SUCCESS:
                console.log(state);
                handleLoginSuccess(state.payload);
                break;
        }
    }, [state]);


    const handleLoginSuccess = async (payload: any) => {
        console.log(payload);
        showMsg("登录成功");
        let val = await form.validateFields()
        if (img) {
            localStorage.setItem("u", val.username);
            localStorage.setItem("p", val.password);
        }
        else {
            localStorage.setItem("u", "");
            localStorage.setItem("p", "");
        }
        navigate("/home/index");
    }



    const handleLogin = async () => {
        try {
            let val = await form.validateFields()
            console.log(ACTIONS.LOGIN_ACTION);
            let loginAction: ActionType<LoginType> = {
                type: ACTIONS.LOGIN_ACTION,
                payload: {...val}
            }
            dispatch(loginAction);
        }
        catch (e) {
            console.log(e);
        }
    }


    const onKeyDown = (e: any) => {
        if (e.code === "Enter") {
            handleLogin();
        }
    }




        return (
            <div className={css.page} >
                <div className={css.midBg}>
                    <span style={{width:'100%',textAlign:"center",color:'#fff',fontSize:40,marginTop:110}}>智能水炮管理系统</span>
                    <div className={css.loginBox}>
                        <span className={css.loginText}>登录</span>
                        <Form form={form}>
                            <div className={css.inputBox}>
                                <img className={css.icon} src={IconAccount}/>
                                <Form.Item name={'username'} className={css.accountBox}>
                                    <input className={css.input} autoComplete="off"/>
                                </Form.Item>
                            </div>
                            <div className={css.inputBox} style={{marginTop:16}}>
                                <img className={css.icon} src={IconPassword}/>
                                <Form.Item name={'password'} className={css.accountBox}>
                                    <input onKeyDown={onKeyDown} type='password' className={css.input} autoComplete="off"/>
                                </Form.Item>
                            </div>
                        </Form>
                        <div className={css.rememberPasswordBox}>
                            <img className={css.selectBox} src={`${img?Select:unSelect}`} onClick={()=>{
                                setImg(!img)
                            }}/>
                            <span className={css.rememberText}>记住密码</span>
                        </div>
                        <div onClick={handleLogin} className={css.loginButton}>登 录</div>
                    </div>
                </div>

            </div>
        );
}

export default Login;
