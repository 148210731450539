import React, {FC, Key} from 'react'
import {Table, Badge, Pagination, Space, Button} from 'antd'
import {ColumnsType} from 'antd/lib/table'

import {useNavigate} from 'react-router-dom'
import dayjs from "dayjs";

interface ServiceProviderTableProps {
    dataSource?: any[]
    /**
     * 总数
     */
    total?: number
    /**
     * 当前页码
     */
    current?: number
    /**
     * 选中的服务商的key
     */
    selectedRowKeys: Key[]
    /**
     * 总页数
     */
    pages?: number
    /** 翻页查询 */
    onPageChange?: (page: number, pageSize?: number) => void
    onDelete?: (companyId: string[]) => void
    onActive?: (params: { id: string[], state: 0 | 1 }) => void
    onEdit?: (records: any) => void
    onSelectedRowKeysChange: (keys: Key[]) => void
    onCheckDetail?: (serviceProvider: any) => void
    pageSize?: number
}

const ServiceProviderTable: FC<ServiceProviderTableProps> = ({
                                                                 dataSource = [],
                                                                 total = 0,
                                                                 current = 0,
                                                                 selectedRowKeys,
                                                                 pages = 0,
    pageSize = 10,
                                                                 onPageChange,
                                                                 onDelete,
                                                                 onActive,
                                                                 onEdit,
                                                                 onSelectedRowKeysChange,
                                                                 onCheckDetail,
                                                             }: ServiceProviderTableProps) => {
    const navigate = useNavigate()
    /**
     * 批量删除
     */
    const onBatchDelete = (): void => {
        onDelete?.(selectedRowKeys as string[])
    }

    /**
     * 批量启用/停用
     */
    const onBatchActive = (state: 0 | 1): void => {
        onActive?.({id: selectedRowKeys as string[], state})
    }

    /**
     * 渲染服务商状态
     * @param state 状态
     */
    const renderState = (state: 0 | 1): JSX.Element => <Badge
        color={state === 1 ? 'green' : '#f50'}
        text={state === 1 ? '正常' : '停用'}
    />

    const renderActions = (record: any): JSX.Element => (
        <Space className={'actions'}>
          <span className={'primary'}
            onClick={()=>onEdit!(record)}
          >
            操作
          </span>
        </Space>
    )

    const renderName = (record: any): JSX.Element => {
        return (
            <span
                style={{color: '#2276d0', cursor: 'pointer'}}
                onClick={() => onCheckDetail?.(record)}
            >{record.operatorName}</span>
        )
    }

    const columns: ColumnsType<any> = [
        {
            title: 'id',
            key: 'id',
            dataIndex: 'id'
        },
        {
            title: '回调地址',
            key: 'notifyUrl',
            dataIndex: 'notifyUrl'
        }
    ]

    return (
        <div className={'table-wrapper'}>
            <div className={'table'}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={{x: 1200}}
                    rowKey={'id'}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        onChange: onSelectedRowKeysChange
                    }}
                />
                <div className={'footer'}>
                    <Space>
                        <Button
                            disabled={selectedRowKeys.length === 0}
                            type={'primary'}
                            onClick={onBatchDelete}
                        >
                            删除
                        </Button>
                        {/*<Button*/}
                        {/*    disabled={selectedRowKeys.length === 0}*/}
                        {/*    type={'primary'}*/}
                        {/*    onClick={() => onBatchActive(0)}*/}
                        {/*>*/}
                        {/*    停用*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    disabled={selectedRowKeys.length === 0}*/}
                        {/*    type={'primary'}*/}
                        {/*    onClick={() => onBatchActive(1)}*/}
                        {/*>*/}
                        {/*    启用*/}
                        {/*</Button>*/}
                    </Space>
                </div>
            </div>
            <div className={'pagination'}>
                <Pagination
                    showQuickJumper
                    showSizeChanger
                    current={current}
                    pageSize={pageSize}
                    showTotal={(total) => `共${total}条记录 第${current}/${pages}页`}
                    total={total}
                    onChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default ServiceProviderTable
