import {ActionType} from "../../../common/types/actionType";
import {ACTIONS} from "../../../constant/actionConstant";

export function addEditReducer(state: any = {}, action: ActionType<any>) {
    if (action.type.includes(ACTIONS.EDIT_ITEM + ACTIONS.RESPONSE_SUCCESS) || action.type.includes(ACTIONS.EDIT_ITEM + ACTIONS.RESPONSE_ERROR)) {
        return Object.assign({}, action);
    }
    else if (action.type.includes(ACTIONS.ADD_ITEM + ACTIONS.RESPONSE_ERROR) || action.type.includes(ACTIONS.ADD_ITEM + ACTIONS.RESPONSE_SUCCESS)) {
        return Object.assign({}, action);
    }
    else if (action.type === ACTIONS.RESET_ADD_EDIT_STATE) {
        return Object.assign({}, action.payload);
    }
    else if (action.type.includes(ACTIONS.DEL_ITEM + ACTIONS.RESPONSE_SUCCESS) || action.type.includes(ACTIONS.DEL_ITEM + ACTIONS.RESPONSE_ERROR)) {
        return Object.assign({}, action);
    }
    else if (action.type.includes(ACTIONS.DISABLE_ENABLE_ITEM + ACTIONS.RESPONSE_SUCCESS) || action.type.includes(ACTIONS.DISABLE_ENABLE_ITEM + ACTIONS.RESPONSE_ERROR)) {
        return Object.assign({}, action);
    }
    return state;
}