import React, { CSSProperties, FC } from 'react'

import SafetyFilledSVG from '../../assets/icons/safety-filled.svg'
// import SafetyFilledSVG from '../icon/assets/icons/safety-filled.svg'
import SafetyOutlinedSVG from '../../assets/icons/safety-outlined.svg'
import CompanyFilledSVG from '../../assets/icons/company-filled.svg'
import CompanyOutlinedSVG from '../../assets/icons/company-outlined.svg'
import ExtinguisherFilledSVG from '../../assets/icons/extinguisher-filled.svg'
import ExtinguisherOutlinedSVG from '../../assets/icons/extinguisher-outlined.svg'
import FileTextFilledSVG from '../../assets/icons/file-text-filled.svg'
import FileTextOutlinedSVG from '../../assets/icons/file-text-outlined.svg'
import ProjectFilledSVG from '../../assets/icons/project-filled.svg'
import ProjectOutlinedSVG from '../../assets/icons/project-outlined.svg'
import WarningFilledSVG from '../../assets/icons/warning-filled.svg'
import WarningOutlinedSVG from '../../assets/icons/warning-outlined.svg'
import SettingFilledSVG from '../../assets/icons/setting-filled.svg'
import SettingOutlinedSVG from '../../assets/icons/setting-outlined.svg'
import UserGroupFilledSVG from '../../assets/icons/user-group-filled.svg'
import UserGroupOutlinedSVG from '../../assets/icons/user-group-outlined.svg'
import RollbackOutlinedSVG from '../../assets/icons/rollback-outlined.svg'
import StopOutlinedSVG from '../../assets/icons/stop-outlined.svg'
import RatingOutlinedSVG from '../../assets/icons/rating-outlined.svg'
import RadarChartOutlinedSVG from '../../assets/icons/radar-chart-outlined.svg'
import ExportOutlinedSVG from '../../assets/icons/export-outlined.svg'
import EditOutlinedSVG from '../../assets/icons/edit-outlined.svg'
import DeleteOutlinedSVG from '../../assets/icons/delete-outlined.svg'
import ConsoleFilledSVG from '../../assets/icons/console-filled.svg'
import MonitorFilledSVG from '../../assets/icons/monitor-filled.svg'
import BoxOutlinedSVG from '../../assets/icons/box-outlined.svg'
import FireOutlinedSVG from '../../assets/icons/fire-outlined.svg'
import HomeOutlinedSVG from '../../assets/icons/home-outlined.svg'
import HomeFilledSVG from '../../assets/icons/home-filled.svg'
import FireGrayOutlinedSVG from '../../assets/icons/fire-gray-outlined.svg'
import RiseFilledSVG from '../../assets/icons/rise-filled.svg'
import OnlineOutlinedSVG from '../../assets/icons/online-outlined.svg'
import OfflineOutlinedSVG from '../../assets/icons/offline-outlined.svg'
import ClockFilledSVG from '../../assets/icons/clock-filled.svg'
import FireFilledSVG from '../../assets/icons/fire-filled.svg'
import GasFilledSVG from '../../assets/icons/gas-filled.svg'
import SmokeFilledSVG from '../../assets/icons/smoke-filled.svg'
import TemperatureFilledSVG from '../../assets/icons/temperature-filled.svg'
import FileAddOutlinedSVG from '../../assets/icons/file-add-outlined.svg'

interface IconProps {
  className?: string
  style?: CSSProperties
  onClick?: () => void
}

/** 安全（实底风格） */
export const SafetyFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ SafetyFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 安全（线框风格） */
export const SafetyOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ SafetyOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 企业（实底风格） */
export const CompanyFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ CompanyFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 企业（线框风格） */
export const CompanyOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ CompanyOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}
/** 灭火器（实底风格） */
export const ExtinguisherFilled: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ ExtinguisherFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 灭火器（线框风格） */
export const ExtinguisherOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ ExtinguisherOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 文件（实底风格） */
export const FileTextFilled: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ FileTextFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 文件（线框风格） */
export const FileTextOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ FileTextOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 项目（实底风格） */
export const ProjectFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ ProjectFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 项目（线框风格） */
export const ProjectOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ ProjectOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 设置（实底风格） */
export const SettingFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ SettingFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 设置（线框风格） */
export const SettingOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ SettingOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 报警（实底风格） */
export const WarningFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ WarningFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 报警（线框风格） */
export const WarningOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ WarningOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 用户组（实底风格） */
export const UserGroupFilled: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ UserGroupFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 用户组（线框风格） */
export const UserGroupOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ UserGroupOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 回滚（线框风格） */
export const RollbackOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ RollbackOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 停止（线框风格） */
export const StopOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ StopOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 评分（线框风格） */
export const RatingOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ RatingOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 雷达图（线框风格） */
export const RadarChartOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ RadarChartOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 导出（线框风格） */
export const ExportOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ ExportOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 编辑（线框风格） */
export const EditOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ EditOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 删除（线框风格） */
export const DeleteOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ DeleteOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 控制台（实底风格） */
export const ConsoleFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ ConsoleFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 监控（实底风格） */
export const MonitorFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ MonitorFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 盒子（线框风格） */
export const BoxOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ BoxOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 火焰（线框风格） */
export const FireOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ FireOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 火焰（线框风格） */
export const FireGrayOutlined: FC<IconProps> = ({
  className,
  style,
  onClick
}: IconProps) => {
  return (
    <img
      className={ className }
      src={ FireGrayOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 首页（实底风格） */
export const HomeFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ HomeFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 首页（线框风格） */
export const HomeOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ HomeOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 增长（实底风格） */
export const RiseFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ RiseFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/**
 * 在线（线框风格）
 */
export const OnlineOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ OnlineOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/**
 * 在线（线框风格）
 */
export const OfflineOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ OfflineOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 时钟（实底风格） */
export const ClockFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ ClockFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 气体（实底风格） */
export const GasFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ GasFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 烟雾（实底风格） */
export const SmokeFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ SmokeFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 火焰（实底风格） */
export const FireFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ FireFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 温度（实底风格） */
export const TemperatureFilled: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ TemperatureFilledSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}

/** 文件新增（线框风格） */
export const FileAddOutlined: FC<IconProps> = ({ className, style, onClick }: IconProps) => {
  return (
    <img
      className={ className }
      src={ FileAddOutlinedSVG }
      style={ style }
      onClick={ onClick }
    />
  )
}
