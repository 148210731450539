import {requestInterface} from "./index";
import axios from "./Interceptor"

export class Request implements requestInterface {
    get<R>(url: string, data?: any): Promise<R> {
        return axios.request<any, R>({
            url,
            params: data,
            method: "get"
        })
    }

    del<R>(url: string, data: any): Promise<R> {
        return axios.request<any, R>({
            url,
            data,
            method: "delete"
        })
    }

    post<R>(url: string, data: any): Promise<R> {
        return axios.request<any, R>({
            url,
            data,
            method: "post"
        })
    }

    put<R>(url: string, data: any): Promise<R> {
        return axios.request<any, R>({
            url,
            data,
            method: "put"
        })
    }

    upLoadFile<R>(url: string, data: any): Promise<R> {
        return axios.request<any, R>({
            url: url,
            data: data,
            method:'post' ,
            headers: {
                'Content-Type': 'multipart/form-data',  // 文件上传
            },
        })
    }
}




