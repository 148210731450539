import axios, {AxiosInstance} from "axios"
import { showMsg, filterObjDelNull, jumpToLogin} from "../../common/common";

let instance: AxiosInstance = axios.create({
    baseURL: "/water-cannon",
    headers: {
        "content-type": "application/json;charset=UTF-8"
    }
})

instance.interceptors.request.use(
    config => {
        let token = sessionStorage.getItem("token");
        if (token !== null && token !== undefined && token !== "") {
            config.headers = {"Authorization": token};
            console.log(config);
            if (config.params !== null) {
                config.params = filterObjDelNull(config.params);
            }
            if (config.data !== null) {
                config.data = filterObjDelNull(config.data);
            }
        }
        return config
    },
    error => Promise.reject(error)
)

instance.interceptors.response.use(
    response => {
        if (response.data.code !== 200) {
            showMsg(response.data.msg);
            // throw Error(JSON.stringify(response));
        }
        return response
    },
    error => {
        console.log(error);
        if (!error.response) {
            showMsg("服务器异常")
        }
        console.log(error.response.data);
        if (error.response.data.code === 3005) {
            sessionStorage.clear();
            jumpToLogin();
        }
        return Promise.reject(error)
    }
)


export default instance
