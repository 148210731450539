import React, {Component} from 'react';
import css from './Search.module.css'
import {Button, Col, Form, FormInstance, Input, Row, Space} from "antd";



interface SearchProps {
    add:Function,
    query:Function,
    reset:Function,
    form: FormInstance
}
function Search(props: SearchProps) {
    return (
        <div className={'search'}>
            <Form form={props.form}>
                <Row gutter={ 24 }>
                    {/*<Col span={ 5 }>*/}
                    {/*    <Form.Item label={'地址编号'} name={'deviceNo'}>*/}
                    {/*        <Input/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col span={ 5 }>*/}
                    {/*    <Form.Item label={'管理员名称'} name={'name'}>*/}
                    {/*        <Input/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    {/*<Col span={ 5 }>*/}
                    {/*    <Form.Item label={'管理员电话'} name={'phoneNumber'}>*/}
                    {/*        <Input/>*/}
                    {/*    </Form.Item>*/}
                    {/*</Col>*/}
                    <Col span={ 2 }>
                        <Form.Item>
                            <Space size={ 16 }>
                                {/*<Button type={ 'primary' } onClick={() => {props.query()}}>*/}
                                {/*    查询*/}
                                {/*</Button>*/}
                                {/*<Button type={ 'default' } onClick={()=>{*/}
                                {/*    props.reset()}}>*/}
                                {/*    重置*/}
                                {/*</Button>*/}
                                <Button type={ 'primary' } onClick={()=>props.add()}>
                                    新增
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}





// class Search extends Component<SearchProps,any> {
//     constructor(p: any) {
//         super(p);
//         console.log(p);
//     }
//
//
//     form = React.createRef<FormInstance>()
//
//
//
//
//     render() {
//         return (
//             <div className={'search'}>
//                 <Form ref={this.form}>
//                     <Row gutter={ 24 }>
//                         <Col span={ 5 }>
//                             <Form.Item label={'公司名称'} name={'companyName'}>
//                                 <Input/>
//                             </Form.Item>
//                         </Col>
//                         <Col span={ 5 }>
//                             <Form.Item label={'管理员名称'} name={'name'}>
//                                 <Input/>
//                             </Form.Item>
//                         </Col>
//                         <Col span={ 5 }>
//                             <Form.Item label={'管理员电话'} name={'phoneNumber'}>
//                                 <Input/>
//                             </Form.Item>
//                         </Col>
//                         <Col span={ 5 }>
//                             <Form.Item>
//                                 <Space size={ 16 }>
//                                     <Button type={ 'primary' } onClick={()=>this.props.query(this.form.current?.getFieldsValue())}>
//                                         查询
//                                     </Button>
//                                     <Button type={ 'default' } onClick={()=>{
//                                         this.form.current?.resetFields()
//                                         this.props.reset()}}>
//                                         重置
//                                     </Button>
//                                     <Button type={ 'primary' } onClick={()=>this.props.add()}>
//                                         新增
//                                     </Button>
//                                 </Space>
//                             </Form.Item>
//                         </Col>
//                     </Row>
//
//                 </Form>
//             </div>
//         );
//     }
// }

export default Search;
