import {ACTIONS} from "../../../constant/actionConstant";
import {ActionType} from "../../../common/types/actionType";
import {Account} from "../../../core/account";

export function userReducer(state: Account = {}, action: ActionType<any>) {
    let {type, payload} = action;
    switch (type) {
        case ACTIONS.LOGIN_ACTION:
            return Object.assign({}, state, action);
        case ACTIONS.LOGIN_ACTION + ACTIONS.RESPONSE_SUCCESS:
            return handleLoginSuccess(state, action);
    }
    return state;
}




function handleLoginSuccess(state: Account, action: any) {
    let {type, payload} = action;
    console.log(payload);
    if (payload.token) {
        sessionStorage.setItem("token", payload.token);
    }
    return Object.assign({}, state, action);
}