import {takeEvery, call, put} from "redux-saga/effects"
import {ACTIONS} from "../../../constant/actionConstant";
import {
    ActionType,
    getActionType,
    getInterfaceErrorAction,
    getNetworkErrorAction
} from "../../../common/types/actionType";
import {ResType} from "../../../common/types/resType";
import {TableCommon} from "../../../core/tableCommon";
import {Company} from "../../../core/company";
import {ErrorConstant} from "../../../constant/errorConstant";
import {requestFnMap} from "./requestFnMap";





export function* listSaga() {
    let key = getKey();
    console.log("sagakey", key);
    yield takeEvery((action: any) => takeOnlySendAction(action, ACTIONS.GET_LIST), doGetCompanyList);
    yield takeEvery((action: any) => takeOnlySendAction(action, ACTIONS.EDIT_ITEM), doEdit);
    yield takeEvery((action: any) => takeOnlySendAction(action, ACTIONS.ADD_ITEM), doAdd);
    yield takeEvery((action: any) => takeOnlySendAction(action, ACTIONS.DEL_ITEM), doDel);
    yield takeEvery((action: any) => takeOnlySendAction(action, ACTIONS.DISABLE_ENABLE_ITEM), doDisableEnable);
}



function getKey(): string {
    return window.location.href.split("/")[window.location.href.split("/").length - 1].replaceAll("/", "");
}


function takeOnlySendAction(action: any, actionType: string): boolean {
    return action.type.includes(actionType) && ((!action.type.includes(ACTIONS.RESPONSE_SUCCESS)) && (!action.type.includes(ACTIONS.RESPONSE_ERROR)));
}


function getRequestFn(actionType: string): any {
    let key = getKey()
    return requestFnMap[key][actionType];
}


function* doGetCompanyList(action: ActionType<any>) {
    console.log("request company list", "<-------", action)
    let key = getKey()
    // let val: CompanyTableRequestType = yield select(state => console.log(state));
    let val = action.payload;
    console.log(val);
    try {
        let res: ResType<any> = yield call(getRequestFn(ACTIONS.GET_LIST), {...val});
        if (res.data.code === ErrorConstant.SUCCESS_CODE) {
            let obj: TableCommon<Company> = {
                current: res.data.data.current,
                list: res.data.data.records,
                pages: res.data.data.pages,
                size: res.data.data.size,
                total: res.data.data.total
            };
            yield put(getActionType(key + ACTIONS.GET_LIST + ACTIONS.RESPONSE_SUCCESS, {...obj}));
        }
        else {
            yield put(getInterfaceErrorAction(key + ACTIONS.GET_LIST + ACTIONS.RESPONSE_ERROR, res.data.message));
        }
    }
    catch (e) {
        yield put(getNetworkErrorAction(key + ACTIONS.GET_LIST + ACTIONS.RESPONSE_ERROR));
    }
}



function* doEdit(action: ActionType<any>) {
    let key = getKey()
    console.log(action.payload);
    try {
        let res: ResType<any> = yield call(getRequestFn(ACTIONS.EDIT_ITEM), {...action.payload});
        console.log(res);
        if (res.data.code === ErrorConstant.SUCCESS_CODE) {
            yield put(getActionType(key + ACTIONS.EDIT_ITEM + ACTIONS.RESPONSE_SUCCESS, {
                state: ErrorConstant.SUCCESS_CODE
            }));
        }
        else {
            yield put(getInterfaceErrorAction(key + ACTIONS.EDIT_ITEM + ACTIONS.RESPONSE_ERROR, res.data.message));
        }
    }
    catch (e) {
        yield put(getNetworkErrorAction(key + ACTIONS.EDIT_ITEM + ACTIONS.RESPONSE_ERROR));
    }
}



function* doAdd(action: ActionType<any>) {
    let key = getKey()
    console.log(action.payload);
    try {
        let res: ResType<any> = yield call(getRequestFn(ACTIONS.ADD_ITEM), {...action.payload});
        console.log(res);
        if (res.data.code === ErrorConstant.SUCCESS_CODE) {
            yield put(getActionType(key + ACTIONS.ADD_ITEM + ACTIONS.RESPONSE_SUCCESS, {
                state: ErrorConstant.SUCCESS_CODE
            }));
        }
        else {
            yield put(getInterfaceErrorAction(key + ACTIONS.ADD_ITEM + ACTIONS.RESPONSE_ERROR, res.data.message));
        }
    }
    catch (e) {
        console.log(e);
        yield put(getNetworkErrorAction(key + ACTIONS.ADD_ITEM + ACTIONS.RESPONSE_ERROR));
    }
}


function* doDel(action: ActionType<any>) {
    let key = getKey()
    console.log(action.payload);
    try {
        let res: ResType<any> = yield call(getRequestFn(ACTIONS.DEL_ITEM), {...action.payload});
        console.log(res);
        if (res.data.code === ErrorConstant.SUCCESS_CODE) {
            yield put(getActionType(key + ACTIONS.DEL_ITEM + ACTIONS.RESPONSE_SUCCESS, {
                state: ErrorConstant.SUCCESS_CODE
            }));
        }
        else {
            yield put(getInterfaceErrorAction(key + ACTIONS.DEL_ITEM + ACTIONS.RESPONSE_ERROR, res.data.message));
        }
    }
    catch (e) {
        yield put(getNetworkErrorAction(key + ACTIONS.DEL_ITEM + ACTIONS.RESPONSE_ERROR));
    }
}


function* doDisableEnable(action: ActionType<any>) {
    let key = getKey()
    console.log(action.payload);
    try {
        let res: ResType<any> = yield call(getRequestFn(ACTIONS.DISABLE_ENABLE_ITEM), {...action.payload});
        console.log(res);
        if (res.data.code === ErrorConstant.SUCCESS_CODE) {
            yield put(getActionType(key + ACTIONS.DISABLE_ENABLE_ITEM + ACTIONS.RESPONSE_SUCCESS, {
                state: ErrorConstant.SUCCESS_CODE
            }));
        }
        else {
            yield put(getInterfaceErrorAction(key + ACTIONS.DISABLE_ENABLE_ITEM + ACTIONS.RESPONSE_ERROR, res.data.message));
        }
    }
    catch (e) {
        yield put(getNetworkErrorAction(key + ACTIONS.DISABLE_ENABLE_ITEM + ACTIONS.RESPONSE_ERROR));
    }
}