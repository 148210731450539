import {useTableAction} from "../../hooks/tableHook";
import {Form, Input, Modal} from "antd";
import ServiceProviderTable from "./component/ServiceProviderTable";
import Search from "./component/Search";
import css from "./index.module.css"
import Ctl from "./component/Ctl";
import {useEffect, useRef, useState} from "react";

function DeviceList(props: any) {

    const [dataSource, searchForm, editForm, search, reset, del, disableEnable, editFn, loading, onPageChange, current, total, pages, addFn, visible, showDialog, hiddenDialog, editVisible, showEditDialog, hiddenEditDialog, pageSize, selectedRowKeys, setSelectedRowKeys] = useTableAction({});

    const [ctlId, setCtlId] = useState("");
    const [ctlDeviceId, setCtlDeviceId] = useState("");
    const ctlRef = useRef<any>(null);



    function ctlDialog(record: any) {
        showEditDialog(record);
        setCtlId(record.deviceIdentifier);
        setCtlDeviceId(record.deviceId)
        setTimeout(() => {
            ctlRef.current.echoState()
        });
    }


    function onOk() {
        hiddenEditDialog();
        ctlRef.current.removeTimer()
    }



    return (
        <div className={css.page}>
            <Search form={searchForm} reset={reset} add={showDialog} query={search}/>
            <ServiceProviderTable
                onPageChange={onPageChange}
                onSelectedRowKeysChange={(e: any)=>{
                    setSelectedRowKeys(e);
                }}
                onEdit={ctlDialog}
                current={ current }
                // current={ companyList.current }
                pages={  pages }
                pageSize={pageSize}
                // pages={  companyList.pages }
                total={ total }
                // total={ companyList?.total }
                dataSource={dataSource}
                selectedRowKeys={selectedRowKeys}
                onActive={(params) => {disableEnable(params.id, params.state)}}
                onDelete={ (userIds) => Modal.confirm({
                    onOk: () => del(userIds),
                    title: '确认删除服务商？',
                    content: '删除后不可恢复！'
                }) }
            />
            <Modal title={'操作'} visible={editVisible} onOk={onOk} onCancel={onOk}>
                <Ctl ref={ctlRef} ctlDeviceId={ctlDeviceId} ctlId={ctlId}/>
            </Modal>
        </div>
    )
}

export default DeviceList;