import {ActionType} from "../../../common/types/actionType";
import {ACTIONS} from "../../../constant/actionConstant";



export function paramsReducer(state: any = {}, action: ActionType<any>) {
    let key = window.location.href.split("/")[window.location.href.split("/").length - 1].replaceAll("/", "");
    let obj: any = {};

    if (action.type.includes(ACTIONS.GET_LIST) && ((!action.type.includes(ACTIONS.RESPONSE_ERROR)) && (!action.type.includes(ACTIONS.RESPONSE_SUCCESS)))) {
        console.log(action.type)
        console.log(action.payload);
        obj[key] = action.payload;
        console.log(obj, "<------obj");
        return Object.assign({}, state, obj);
    }
    if (action.type === ACTIONS.RESET_PARAMS_STATE) {
        obj[key] = {};
        return  Object.assign({}, state, obj);
    }
    if (action.type === ACTIONS.SET_PARAMS_STATE) {

    }

    return state;
}