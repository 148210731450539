import {takeLatest, select, SelectEffect, call, put} from "redux-saga/effects"
import {ACTIONS} from "../../../constant/actionConstant";
import {
    ActionType,
    getActionType,
    getInterfaceErrorAction,
    getNetworkErrorAction
} from "../../../common/types/actionType";
import {LoginType} from "../../../common/types/loginType";
import {login} from "../../../service/login";
import {ResType} from "../../../common/types/resType";
import {showMsg} from "../../../common/common";
import {ErrorConstant} from "../../../constant/errorConstant";


export function* permissionSaga() {
    yield takeLatest(ACTIONS.LOGIN_ACTION, doLogin);
}


function* doLogin(action: ActionType<LoginType>) {
    let val: LoginType = yield select(state => state.user.payload);
    console.log(val);
    try {
        if (val.username === undefined || val.username === "") {
            alert("用户名必填");
            return;
        }
        if (val.password === undefined || val.password === "") {
            alert("密码必填");
            return;
        }
        let res: ResType<any> = yield call(login, val.username, val.password);
        if (res.data.code === 200) {
            yield put(getActionType<any>(ACTIONS.LOGIN_ACTION + ACTIONS.RESPONSE_SUCCESS, {token: res.data.data}))
        }
        else {
            yield put(getInterfaceErrorAction(ACTIONS.LOGIN_ACTION + ACTIONS.RESPONSE_ERROR, res.data.message))
        }
    }
    catch (err) {
        yield put(getNetworkErrorAction(ACTIONS.LOGIN_ACTION + ACTIONS.RESPONSE_ERROR))
    }
}