import React, { FC, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import styles from './Content.module.css'

const Content: FC = () => {
  return (
    <div className={ styles.container }>
      <Suspense fallback={ <div>Loading</div> }>
        <Outlet />
      </Suspense>
    </div>
  )
}

export default Content
